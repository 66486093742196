import React from 'react';
import { ProgressBar, Badge } from 'react-bootstrap';
import { TaggableComponent } from 'react-otmonitor-websocket';
/**
 * @copyright Optimum Tracker
 * @author bblin
 */
class Trackers extends TaggableComponent
{
	constructor(props)
	{
		super(props);
		this.bindTags([['NbTrackersOk'], ['NbTrackersManual'], ['NbTrackersError']]);
	}

	

	round(percent)
	{
		if(percent % 1 === 0) {
			return percent;
		}
		return Math.round(percent * 10) / 10;
	}
	
	adjustProgress(percent)
	{
		if(percent === 100) {
			return 100;
		}
		if(percent === 0 || percent === null) {
			return 0;
		}
		const floors = [87.5, 75, 62.5, 50, 37.5, 25];
		for(let floor of floors) {
			if(percent >= floor) {
				return floor;
			}
		}
		return 12.5;
	}

	
	
	componentDidMount(prevProps, prevState)
	{
		super.componentDidMount(prevProps, prevState);
		this.props.powerplantsData.setNbTrackers(this.props.app_id, this.state.NbTrackersOk, this.state.NbTrackersManual, this.state.NbTrackersError);
	}
			
	componentDidUpdate(prevProps, prevState)
	{
		super.componentDidUpdate(prevProps, prevState);
		this.props.powerplantsData.setNbTrackers(this.props.app_id, this.state.NbTrackersOk, this.state.NbTrackersManual, this.state.NbTrackersError);
	}

	NoDataStyle = function(options) {
		return {
			borderRadius:'20px', 
			width:'100%', 
			marginRight:'8vw', 
			color:'#5bc0de', 
			backgroundColor:'#5bc0de'
		}
	
	}

	render()
	{
		const total = this.state.NbTrackersOk + this.state.NbTrackersManual + this.state.NbTrackersError;
		const percentAuto = (this.round(this.state.NbTrackersOk / total * 100)).toFixed(1);
		const percentManual = (this.state.NbTrackersManual / total * 100).toFixed(1);;
		const percentOff = this.state.NbTrackersError / total * 100;
	

		let nowManual = this.adjustProgress(percentManual);
		let nowOff = this.adjustProgress(percentOff);
		let nowAuto = 100 - nowOff - nowManual;
	
		if((percentAuto === 0 || percentAuto === null) && nowAuto > 0) {
			nowAuto = 0;
		}

		if(this.state.NbTrackersOk === null && this.state.NbTrackersManual === null && this.state.NbTrackersError === null) {
			nowManual = 0;
			nowAuto = 0;
			nowOff = 0;
		}

		let infoTracker = `${Math.trunc(percentAuto)}% / ${total}`;


		return (
			<React.Fragment>
				<td className={this.props.lostRemoteConnection ? "lost-remote-connection-true-bg text-center trackers-on" : "trackers-on text-center"}>
					<div className={percentAuto ==='NaN' ?"not-message":"message-position text-center"}>
							{' '+ infoTracker}	
					</div>
					<ProgressBar bsStyle={percentAuto!=='NaN' ? "success":"info"} bsClass="tracker-progress-bar" className={ percentAuto!=='NaN' ? "" : "progress-bar active progress-bar-striped" }  style={ percentAuto!=='NaN' ? {borderRadius:'20px', width:'100%', marginRight:'5vw', fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif', backgroundColor:'#B2B6BB'}: this.NoDataStyle()} now={percentAuto!=='NaN' ? parseFloat(percentAuto) : 0} />
				</td>
				<td className={this.props.lostRemoteConnection ? "lost-remote-connection-true-bg text-center" : "text-center"}>
					{ !this.state.NbTrackersManual || this.state.NbTrackersManual===0 ? "": 
					<Badge bsClass={percentAuto!=='NaN' ?"tracker-badge-manual add-margin":"tracker-badge-manual remove-margin"}>{this.state.NbTrackersManual ? this.state.NbTrackersManual:0}</Badge> 
					}
				</td>
				<td className={this.props.lostRemoteConnection ? "lost-remote-connection-true-bg text-center" : "text-center"}>
					{ !this.state.NbTrackersError || this.state.NbTrackersError===0 ? "": 
					<Badge bsClass={percentAuto!=='NaN' ?"tracker-badge-error add-margin":"tracker-badge-error remove-margin"}>{this.state.NbTrackersError ? this.state.NbTrackersError:0}</Badge>
					}
				</td>
			</React.Fragment>
		);
	}
	
}

Trackers.defaultProps = {
	app_id: null,
	powerplantsData: function(){}
}

export default Trackers;