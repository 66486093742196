import React from 'react';

class AbstractSorting extends React.Component
{	
	constructor() {
	
	super();
        this.state = {
            sortingByName: false,
            sortingByPower:false,
            sortingByWindSpeed: false,
            sortingByNbTrackersOk: false,
            sortingByNbTrackersManual: false,
            sortingByNbTrackersOff: false
        }
	}

    handleSortName =()=>{
        this.setState({sortingByName: !this.state.sortingByName});
        this.props.setSorting('name', this.state.sortingByName);
    }

    handleSortPower=()=>{
        this.setState({sortingByPower: !this.state.sortingByPower});
        this.props.setSorting('power', this.state.sortingByPower);
    }

    handleSortWindSpeed=()=>{
        this.setState({sortingByWindSpeed: !this.state.sortingByWindSpeed});
        this.props.setSorting('windSpeed', this.state.sortingByWindSpeed);
    }

    handleSortNbTrackersOk=()=>{
        this.setState({sortingByNbTrackersOk: !this.state.sortingByNbTrackersOk});
        this.props.setSorting('percentAuto', this.state.sortingByNbTrackersOk);
    }
    
    handleSortNbTrackersManual=()=>{
        this.setState({sortingByNbTrackersManual: !this.state.sortingByNbTrackersManual});
        this.props.setSorting('nbTrackersManual', this.state.sortingByNbTrackersManual);
    }
    
    handleSortNbTrackersError=()=>{
        this.setState({sortingByNbTrackersError: !this.state.sortingByNbTrackersError});
        this.props.setSorting('nbTrackersError', !this.state.sortingByNbTrackersError);
    }

}

export default AbstractSorting;