import React from 'react';
import { TaggableComponent } from 'react-otmonitor-websocket';
import { ProgressBar } from 'react-bootstrap';

/**
 * @copyright Optimum Tracker
 * @author bblin
 */
class Power extends TaggableComponent
{
	constructor(props)
	{
		super(props);
		this.bindTag('PowerSimul');
	}

	render()

	{	
		const value = (this.state.PowerSimul / 1000).toFixed(2);
		let power = `${this.props.total} MW`;
		const pourcent = ((parseFloat(value) / this.props.total)*100);
	
		return (
				<div className="powerplant-power">
					<div className="message-power-position">
								{' '+ power}	
						</div>
					<div>
						<ProgressBar now={ parseFloat(pourcent) } label={0} bsClass={parseInt(this.props.total, 10)===0 ?"no-power-progress-bar":"power-progress-bar"} style={{background:'linear-gradient(0deg, #B2B6BB, #B2B6BB), linear-gradient(0deg, #B2B6BB, #B2B6BB), #B2B6BB', margin:'0 auto', borderRadius: '25px'}}/>
					</div>
				</div>
		);
	}
	
}

Power.defaultProps = {
	app_id: null,
	total: 0
}

export default Power;