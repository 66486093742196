import React from 'react';
import { TaggableComponent } from 'react-otmonitor-websocket';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Power from './Power';
import Trackers from './Trackers';
import services from './../../lib/Services';
import './powerplants.css';

/**
 * Powerplants' Table for desktop screen.
 * @copyright Optimum Tracker
 * @author wkoffi
 */
class PowerplantTable extends TaggableComponent
{
	constructor(props)
	{
		super(props);
		this.bindTags([['LostRemoteConnection'], ['WindSpeed'], ['WindSafety'], ['WindDirection'], ['WindDirectionDisplay']]);
	}


	getDirectionAsString(R_DirectionVent)
	{
		if(R_DirectionVent < 22.5 || R_DirectionVent > 337.5)
			return 'N';
		if(R_DirectionVent < 67.5 && R_DirectionVent > 22.5)
			return 'NE';
		if(R_DirectionVent < 112.5 && R_DirectionVent > 67.5)
			return 'E';
		if(R_DirectionVent < 157.5 && R_DirectionVent > 112.5)
			return 'SE';
		if(R_DirectionVent < 202.5 && R_DirectionVent > 157.5)
			return 'S';
		if(R_DirectionVent < 247.5 && R_DirectionVent > 202.5)
			return 'SW';
		if(R_DirectionVent < 292.5 && R_DirectionVent > 247.5)
			return 'W';
		return 'NW';
	}

	 popoverZoomLocation =()=> {
		return <Popover id="popover-zoom-location" title="">
					<span>Zoom on location</span>
				</Popover>
		
	}

	popoverWindSafety =()=> {
		return <Popover id="popover-wind-safety" title="">
					{ this.state.WindSafety ? <span>Wind safety ON</span> :  <span>No wind safety</span> }
			   </Popover>
	}


	componentDidMount(prevProps, prevState)
	{
		super.componentDidMount(prevProps, prevState);
		this.updateData();
	}

	componentDidUpdate(prevProps, prevState)
	{
		super.componentDidUpdate(prevProps, prevState);
		this.updateData();
	}

	updateData()
	{
		this.props.powerplantsData.setWindSafety(this.props.app_id, this.state.WindSafety);
		this.props.powerplantsData.setLostRemoteConnection(this.props.app_id, this.state.LostRemoteConnection);
		this.props.powerplantsData.setWindSpeed(this.props.app_id, this.state.WindSpeed);
	}
	
	render()
	{   
		if(this.props.displayed === false) return '';
		return (
                <tr>
                    <td className={this.state.LostRemoteConnection ? "lost-remote-connection-true-bg text-left" : "text-left"}>
                        <a className="name" href={ services.getMonitoringUrl(this.props.app_id) } target="_blank"><strong>{this.props.name}</strong></a>
                        { this.state.LostRemoteConnection && 
							<img
								src="/images/logo/lostconnection2.png"
								width="15"
								height="15"
								className="location-image logo-connection-responsive lost-remote-conn-margin"
								alt="Lost remote connection"
							/> }
                    </td>
                    <td className={this.state.LostRemoteConnection ? "lost-remote-connection-true-bg text-center" : "text-center"}>
                        <Power app_id={this.props.app_id} powerplantsData={this.props.powerplantsData} total={this.props.power}/>
                    </td>
                    <Trackers app_id={this.props.app_id} powerplantsData={this.props.powerplantsData} lostRemoteConnection={this.state.LostRemoteConnection}/>
                    <td className={this.state.LostRemoteConnection ? "lost-remote-connection-true-bg text-center" : "text-center"}>
                        { Math.ceil(this.state.WindSpeed*3.6) } km/h { this.state.WindDirectionDisplay && this.getDirectionAsString(this.state.WindDirection) }
                    </td>
                    <td className={this.state.LostRemoteConnection ? "lost-remote-connection-true-bg text-center" : "text-center"}>
                        <OverlayTrigger
							trigger={['hover', 'focus']}
							placement="bottom"
							overlay={this.popoverWindSafety()}
							>
                            <img
                                src={this.state.WindSafety ? "/images/logo/shields3.png" : "/images/logo/shield1.png"}
                                width="15"
                                height="15"
                                className={this.state.WindSpeed ? "location-image-wind-speed windsafety-logo-responsive wind-safety-margin" :"location-image windsafety-logo-responsive wind-safety-margin"}
                                alt="Search wind powers"
                                />
						</OverlayTrigger>
                    </td>
                    <td className={this.state.LostRemoteConnection ? "lost-remote-connection-true-bg text-center" : "text-center"}>
                        <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="bottom"
                            overlay={this.popoverZoomLocation()}
                            >
                            <img
                                src="/images/logo/location2.png"
                                width="25"
                                height="25"
                                onClick={() => this.props.setSelectedPowerplantOnMap(this.props.app_id)}
                                className={this.state.WindSpeed ? "location-image-wind-speed mx-auto location-logo-responsive location-img-logo" : "location-image mx-auto location-logo-responsive location-img-logo"}
                                alt="Powerplants location logo"
                                />
						</OverlayTrigger>
                    </td>
                </tr>
		);
	}
}

PowerplantTable.defaultProps = {
	name: '',
	power: 0,
	app_id: null,
	setSelectedPowerplantOnMap: function(){},
	powerplantsData: function(){},
	displayed: true
};

export default PowerplantTable;