import React from 'react';
import { Grid, ListGroup, Table } from 'react-bootstrap';
import PowerplantForMobile from '../Powerplant/PowerplantForMobile';
import PowerplantTable from './../Powerplant/PowerplantTable';
import Overview from './../Map/Overview';
import './Body.css';
import Filters from'./Filters';
import SortingBarTableHeader from './SortingBarTableHeader';
import SortingBarMobile from './SortingBarMobile';
import PowerplantsData from './Service/PowerplantData';
import { Scrollbar } from "react-scrollbars-custom";

class Body extends React.Component
{	
	constructor(props)
	{
		super(props);
		this.state = {
			// States for filtering
			displays: new Map(),
			filters: {
			  app_id: null,
              name: '',
              lostRemoteConnection: false,
              windSafety: false
			},
			// States for sorting
			sortingData: 'name',
			sortingAsc: true,
			// Locate powerplant on map
			selectedPowerplantOnMap: null,
			// Height of scrollbar
			height: null,
			// Width of scrollbar
			width: null
		}
		// Collect powerplants data used for filtering and sorting
		this.powerplantsData = new PowerplantsData();
		this.powerplantsData.on('update', (data) => {
			if(data === 'lostRemoteConnection' || data === 'windSafety') {
				this.filter();
			} else {
				this.forceUpdate();
			}
		})
	}

	componentWillMount(){
		this.setState({height: (window.innerHeight - (window.innerHeight/9)) + 'px'});
		this.setState({width: window.innerWidth});
	}

	setSelectedPowerplantOnMap = (app_id) => {
		this.setState({selectedPowerplantOnMap: app_id});
	}

	setSorting = (data, asc) => {
		this.setState({sortingData: data, sortingAsc: asc});
	}

	setFilter = (data, enable) => {
		const filters = this.state.filters;
		filters[data] = enable;
		this.setState({filters});
		this.filter();
	}

	BodyStyle = function(options) {
		return {
			paddingLeft:'50px', 
			marginBottom:'0'
		}
	
	}
	
	/**
	 * Update the state of powerplant visibility according to filtering states
	 */
	filter()
	{
		const displays = this.state.displays;
		const powerplants = Array.from(this.props.powerplants.entries());
		powerplants.forEach(pp => displays.set(pp[0], true));
		if(this.state.filters.app_id !== null) {
			powerplants.forEach(pp => displays.set(pp[0], false));
			displays.set(this.state.filters.app_id, true);
		}
		if(this.state.filters.name !== '') {
			const app_ids = powerplants.filter(pp => !(pp[1].name.toLowerCase().includes(this.state.filters.name.toLowerCase()))).map(pp => pp[0]);
			app_ids.forEach(app_id => displays.set(app_id, false));
		}
		if(this.state.filters.windSafety === true) {
			const app_ids = powerplants.filter(pp => !this.powerplantsData.getWindSafetyByAppId(pp[0])).map(pp => pp[0]);
			app_ids.forEach(app_id => displays.set(app_id, false));
		}
		if(this.state.filters.lostRemoteConnection === true) {
			const app_ids = powerplants.filter(pp => this.powerplantsData.getLostRemoteConnectionByAppId(pp[0])).map(pp => pp[0]);
			app_ids.forEach(app_id => displays.set(app_id, false));
			console.log(this.powerplantsData.getLostRemoteConnection());
		}
		this.setState({displays});
	}

	/**
	 * Sort a list of powerplants according to state
	 * @param Array of [app_id => data]
	 */
	sort(powerplants)
	{
		let sortingData;
		switch(this.state.sortingData) {
			case 'windSpeed':
				sortingData = this.powerplantsData.getWindSpeed();
				powerplants.sort((ppA, ppB) => sortingData.get(ppA[0]) - sortingData.get(ppB[0]));
				break;
			case 'percentAuto':
				sortingData = this.powerplantsData.getPercentAuto();
				powerplants.sort((ppA, ppB) => sortingData.get(ppA[0]) - sortingData.get(ppB[0]));
				break;
			case 'nbTrackersManual':
				sortingData = this.powerplantsData.getNbTrackersManual();
				powerplants.sort((ppA, ppB) => sortingData.get(ppA[0]) - sortingData.get(ppB[0]));
				break;
			case 'nbTrackersError':
				sortingData = this.powerplantsData.getNbTrackersError();
				powerplants.sort((ppA, ppB) => sortingData.get(ppA[0]) - sortingData.get(ppB[0]));
				break;
			case 'power':
				powerplants.sort((ppA, ppB) => ppA[1].total_power - ppB[1].total_power);
				break;
			case 'name':
				powerplants.sort((ppA, ppB) => ppA[1].name.localeCompare(ppB[1].name));
				break;	
			default:
				break;
		}
		if(this.state.sortingAsc === false) {
			powerplants.reverse();
		}
	}

	render()
	{
		const powerplants = Array.from(this.props.powerplants.entries());
		this.sort(powerplants);

		//To change the component to display relative to the screen's size
		var ToDisplay;
		if (this.state.width <= 991){
			//Powerplants' display for mobile screen
			ToDisplay = PowerplantForMobile;
		} else {
			//Powerplants' display for desktop screen
			ToDisplay = PowerplantTable;
		}

		return (
			<div className="main-body">
				<Filters total = {this.props.total} setFilter ={this.setFilter}/>
				<SortingBarMobile className="sorting-dropdown" setSorting = {this.setSorting}/>
				<Grid fluid className="body" style={this.BodyStyle()}>
					<ListGroup className="col-md-6">
						<Scrollbar style={{ width: '100%', height: this.state.height, color:'#FFFFFF', backgroundColor:'#0E202D' }}>
							<Table>
								<SortingBarTableHeader setSorting = {this.setSorting}/>
									<tbody>
										{	powerplants.map(pp => <ToDisplay powerplantsData={this.powerplantsData}
												displayed={this.state.displays.get(pp[0])}
												key={pp[0]}
												app_id={pp[0]}
												name={pp[1].name}
												power={pp[1].total_power}
												latitude={pp[1].latitude}
												longitude={pp[1].longitude}
												mobileScreen = {true}
												setSelectedPowerplantOnMap={this.setSelectedPowerplantOnMap} />
											)
										}
									</tbody>
							</Table>
						</Scrollbar>
					</ListGroup>
					<div className="col-md-6 map-style">
						<div>
							<Overview powerplants={this.props.powerplants} selectedPowerplantOnMap={this.state.selectedPowerplantOnMap} setFilter={this.setFilter} />
						</div>
					</div>
				</Grid>
			</div>
		);
	}
}

Body.defaultProps = {
	powerplants: new Map()
};

export default Body