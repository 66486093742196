import React from 'react';
import './Login.css';
import { Navbar } from 'react-bootstrap';
/**
 * Display Navbar
 * @copyright Optimum Tracker
 * @author wkoffi
 */
function LoginNavbar()
{
	return (
        <Navbar  className="first-navbar">
            <div className="navbar-items">
                <div>
                    <a href="http://www.optimum-tracker.com" rel="noopener noreferrer" target="_blank">
                        <img
                        src="/images/logo/Logo-white-Optimum-Tracker.svg"
                        width="240px"
                        height="100px"
                        className="logo-tracker"
                        alt="Optimum Tracker"
                    />
                    </a>
                </div>
            </div> 
        </Navbar>
	);
}

export default LoginNavbar;