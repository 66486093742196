import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import Login from './Component/Authentication/Login';
//import App from './Component/App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';

//ReactDOM.render(<App/>, document.getElementById('root'));
ReactDOM.render(<Login/>, document.getElementById('root'));
registerServiceWorker();
