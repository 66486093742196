import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import * as L from "leaflet";

const BLUE_ICON_URL = 'marker-icon-2x-blue.png';
const RED_ICON_URL = 'marker-icon-2x-red.png';

class Location extends React.Component
{
	//  Create the Icon
	LeafIcon = L.Icon.extend({
		options: {}
	});
	
	getIcon()
	{
		return new this.LeafIcon({
			iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/' + (this.props.selected ? RED_ICON_URL : BLUE_ICON_URL),
			shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [1, -34],
			shadowSize: [41, 41]
			});
	}
		
	// This function will change the state's icon
	selectLocation = () => {
		if(this.props.selected === true) {
			this.props.setFilter(null);
		} else {
			this.props.setFilter(this.props.app_id);
		}
	}
	
	render()
	{
		return (
				<Marker position={[this.props.lat, this.props.lon]} icon={ this.getIcon() } onClick= {this.selectLocation}>
					<Popup>{this.props.name}</Popup>
				</Marker>
		);
	}
}

Location.defaultProps = {
	app_id: null,
	setFilter: function(){},
	selected: false
};
export default Location;