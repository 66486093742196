const EventEmitter = require('events');

class PowerplantsData extends EventEmitter
{

	constructor()
	{
		super();
        this.windSpeed = new Map();
        this.nbTrackerOk = new Map();
        this.nbTrackerManual = new Map();
        this.nbTrackerError = new Map();
        this.lostRemoteConnection = new Map();
        this.windSafety = new Map();
	}

	getWindSpeed()
	{
		return this.windSpeed;
	}

	getNbTrackersOk()
	{
		return this.nbTrackerOk;
	}

	getNbTrackersManual()
	{
		return this.nbTrackerManual;
	}

	getNbTrackersError()
	{
		return this.nbTrackerError;
	}

	getPercentAuto() {
        const percentAuto = new Map();

        this.nbTrackerOk.forEach((nbOk, app_id) => {
            const nbManual = this.nbTrackerManual.get(app_id) || 0;
            const nbError = this.nbTrackerError.get(app_id) || 0;

            const total = nbOk + nbManual + nbError;

            if (total > 0) {
                const percent = ((nbOk / total) * 100).toFixed(1);
                percentAuto.set(app_id, parseFloat(percent));
            } else {
                percentAuto.set(app_id, 0);
            }
        });

        return percentAuto;
    }

    getPercentAutoByAppId(app_id) {
        return this.getPercentAuto().get(app_id);
    }
	
	getLostRemoteConnection()
	{
		return this.lostRemoteConnection;
	}

	getLostRemoteConnectionByAppId(app_id)
	{
		return this.lostRemoteConnection.get(app_id);
	}

	getWindSafetyByAppId(app_id)
	{
		return this.windSafety.get(app_id);
	}

	setNbTrackers(app_id, nbTrackerOk , nbTrackerManual, nbTrackerError)
	{
		const update = (this.nbTrackerOk.has(app_id) && (this.nbTrackerOk.get(app_id) !== nbTrackerOk || this.nbTrackerManual.get(app_id) !== nbTrackerManual || this.nbTrackerError.get(app_id) !== nbTrackerError));
		this.nbTrackerOk.set(app_id, nbTrackerOk);
		this.nbTrackerManual.set(app_id, nbTrackerManual);
		this.nbTrackerError.set(app_id, nbTrackerError);
		if(update) this.emit('update', 'nbTrackers');
		return this;
	}

	setWindSafety(app_id, windSafety)
	{
		const update = (this.windSafety.has(app_id) && this.windSafety.get(app_id) !== windSafety);
		this.windSafety.set(app_id, windSafety);
		if (update) this.emit('update', 'windSafety');
    	return this;
	}

    setLostRemoteConnection(app_id, lostRemoteConnection)
    {
    	const update = (this.lostRemoteConnection.has(app_id) && this.lostRemoteConnection.get(app_id) !== lostRemoteConnection);
    	this.lostRemoteConnection.set(app_id, lostRemoteConnection);
		if (update) this.emit('update', 'lostRemoteConnection');
    	return this;
	}

    setWindSpeed(app_id, windSpeed)
    {
    	const update = (this.windSpeed.has(app_id) && this.windSpeed.get(app_id) !== windSpeed);
    	this.windSpeed.set(app_id, windSpeed);
		if (update) this.emit('update', 'windSpeed');
        return this;
	}

}

export default PowerplantsData;