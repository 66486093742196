const MAX_ZOOM = 13;

/**
 * @copyright Optimum Tracker
 * @author bblin
 */
class ZoomLevel
{
	/**
	 * Get width / height of each zoom level
	 * @return Map
	 */
	static getZoomBoundaries()
	{
		const bounds = new Map();
		for(let i = 0; i <= MAX_ZOOM; i++) {
			bounds.set(i, [180/Math.pow(2, i), 360/Math.pow(2, i)]);
		}
		return bounds;
	}
	
	/**
	 * Get zoom level related to a width / height
	 * @return int
	 */
	static selectZoom(lat_diff, lon_diff)
	{
		const bounds = ZoomLevel.getZoomBoundaries();
		for(let i = MAX_ZOOM; i >= 0 ; i--) {
			if(bounds.get(i)[0] > lat_diff && bounds.get(i)[1] > lon_diff) {
				return i + 1 + parseInt(i / 4, 10);
			}
		}
		return 0;
	}
	
	/**
	 * Get zoom level related to min / max coordinates
	 */
	static get(min_lat, min_lon, max_lat, max_lon)
	{
		const lat_diff = Math.abs(max_lat - min_lat);
		const lon_diff = Math.abs(max_lon - min_lon);

		if(lat_diff === 0 && lon_diff === 0) {
			return MAX_ZOOM;
		}
		
		return ZoomLevel.selectZoom(lat_diff, lon_diff);
	}
}

export default ZoomLevel;