import React from 'react';
import { TaggableComponent } from 'react-otmonitor-websocket';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Power from './Power';
import Trackers from './Trackers';
import services from '../../lib/Services';
import './powerplants.css';

/**
 * Powerplants' Table for mobile screen.
 * @copyright Optimum Tracker
 * @author wkoffi
 */
class PowerplantForMobile extends TaggableComponent
{
	constructor(props)
	{
		super(props);
		this.bindTags([['LostRemoteConnection'], ['WindSpeed'], ['WindSafety'], ['WindDirection'], ['WindDirectionDisplay']]);
	}


	getDirectionAsString(R_DirectionVent)
	{
		if(R_DirectionVent < 22.5 || R_DirectionVent > 337.5)
			return 'N';
		if(R_DirectionVent < 67.5 && R_DirectionVent > 22.5)
			return 'NE';
		if(R_DirectionVent < 112.5 && R_DirectionVent > 67.5)
			return 'E';
		if(R_DirectionVent < 157.5 && R_DirectionVent > 112.5)
			return 'SE';
		if(R_DirectionVent < 202.5 && R_DirectionVent > 157.5)
			return 'S';
		if(R_DirectionVent < 247.5 && R_DirectionVent > 202.5)
			return 'SW';
		if(R_DirectionVent < 292.5 && R_DirectionVent > 247.5)
			return 'W';
		return 'NW';
	}

	 popoverZoomLocation =()=> {
		return <Popover id="popover-zoom-location" title="">
					<span>Zoom on location</span>
				</Popover>
		
	}

	popoverWindSafety =()=> {
		return <Popover id="popover-wind-safety" title="">
					{ this.state.WindSafety ? <span>Wind safety ON</span> :  <span>No wind safety</span> }
			   </Popover>
	}


	componentDidMount(prevProps, prevState)
	{
		super.componentDidMount(prevProps, prevState);
		this.updateData();
	}

	componentDidUpdate(prevProps, prevState)
	{
		super.componentDidUpdate(prevProps, prevState);
		this.updateData();
	}

	updateData()
	{
		this.props.powerplantsData.setWindSafety(this.props.app_id, this.state.WindSafety);
		this.props.powerplantsData.setLostRemoteConnection(this.props.app_id, this.state.LostRemoteConnection);
		this.props.powerplantsData.setWindSpeed(this.props.app_id, this.state.WindSpeed);
	}

	getLength = (number) => {
		let nb = Math.ceil(number)
		return nb.toString().length;
	};
	
	render()
	{
		if(this.props.displayed === false) return '';
		return (
				<div className={this.state.LostRemoteConnection ? "lost-remote-connection-true powerplant-style-screen-mode" : "central-items lost-remote-connection-false row powerplant-style-screen-mode"}>
					<div className="col-md-3 row another-alignement">
					    <div className="col-md-9 name-padding">
							<a className="name" href={ services.getMonitoringUrl(this.props.app_id) } target="_blank"><strong>{this.props.name}</strong></a>
						</div>
						<div className="col-md-3">
							{ this.state.LostRemoteConnection && 
							<img
								src="/images/logo/lostconnection2.png"
								width="15"
								height="15"
								className="location-image location-logo-responsive lost-remote-conn-margin"
								alt="Lost remote connection"
							/> }
						</div>
					</div>
					<div className="col-md-3 power-position"><Power app_id={this.props.app_id} total={this.props.power} /></div>
					<div className="col-md-4 powerplant-tracker"><Trackers app_id={this.props.app_id} powerplantsData={this.props.powerplantsData}/></div>
					<div className={ this.state.WindSpeed ? this.getLength(this.state.WindSpeed) === 2 ? "col-md-2 wind wind-location2" : "col-md-2 wind wind-location" : "col-md-2 wind wind-location"} style={{paddingBottom: '10px', paddingTop: '7px'}}>
						<span className="powerplant-wind"> { Math.ceil(this.state.WindSpeed*3.6) } km/h { this.state.WindDirectionDisplay && this.getDirectionAsString(this.state.WindDirection) }</span>
							<OverlayTrigger
									trigger={['hover', 'focus']}
									placement="bottom"
									overlay={this.popoverWindSafety()}
									>
								<img
									src={this.state.WindSafety ? "/images/logo/shields3.png" : "/images/logo/shield1.png"}
									width="15"
									height="15"
									className={this.state.WindSpeed ? "location-image-wind-speed windsafety-logo-responsive wind-safety-margin" :"location-image windsafety-logo-responsive wind-safety-margin"}
									alt="Search wind powers"
									/>
							</OverlayTrigger>
							<OverlayTrigger
								trigger={['hover', 'focus']}
								placement="bottom"
								overlay={this.popoverZoomLocation()}
								>
								<img
									src="/images/logo/location2.png"
									width="25"
									height="25"
									onClick={() => this.props.setSelectedPowerplantOnMap(this.props.app_id)}
									className={this.state.WindSpeed ? "location-image-wind-speed location-logo-responsive location-img-logo" : "location-image location-logo-responsive location-img-logo"}
									alt="Powerplants location logo"
									/>
							</OverlayTrigger>
					</div>
				</div>
		);
	}
}

PowerplantForMobile.defaultProps = {
	name: '',
	power: 0,
	app_id: null,
	setSelectedPowerplantOnMap: function(){},
	powerplantsData: function(){},
	displayed: true
};

export default PowerplantForMobile;
