import jwtDecode from 'jwt-decode';

/**
 * Json Web Token
 * 
 * @copyright Optimum Tracker
 * @author bblin
 */
class Token
{
	constructor()
	{
		this.value = null
	}
	
	set(value)
	{
		this.value = value;
		return this;
	}
	
	get()
	{
		return this.value;
	}
	
	decode()
	{
		return jwtDecode(this.value);
	}
	
	serialize()
	{
		return this.value;
	}
	
	deserialize(raw)
	{
		this.set(raw);
		return this;
	}
}

export default Token;