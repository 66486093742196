import React from 'react';
import Body from './Layout/Body';
import services from './../lib/Services';

class App extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = { powerplants: new Map(), height: null, width: window.innerWidth };
	}
	
	componentDidMount()
	{
		const pps = this.state.powerplants;
		services.getUser().getAllAppId().forEach( app_id => {
			services.getApi().getModules(app_id).then(modules => {
				services.getAuthorization(app_id).setModules(modules);
				services.getApi().getPowerplant(app_id).then(parameters => {
					pps.set(app_id, parameters);
					this.setState({ powerplants: pps});
				});
		    });
		} );
		
	}

	componentWillMount(){
		//Mini screen
		if(this.state.width >= 992 && this.state.width <= 1200){
			this.setState({height: (window.innerHeight/500)*7 + 'vh'});
		} else{
			this.setState({height: (window.innerHeight/500) + 'vh'});
		}
	}
	
	render()
	{

		var total = 0;
		this.state.powerplants.forEach( pp => {
			total += Number(pp.total_power);
		});	
		
		const { height } = this.state;
		
		return (
				<div style={{background:'#0E202D', paddingBottom: height}}>
					<Body total = { total } powerplants = {this.state.powerplants } />
				</div>
		);
	}
}

export default App;