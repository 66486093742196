import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import AbstractSorting from './Service/AbstractSorting';

/**
 * Mobile's Dropdown for sorting powerplants.
 * @copyright Optimum Tracker
 * @author wkoffi
 */

class SortingBarMobile extends AbstractSorting
{	
	selectValue=(val)=>{
        if(val.value === "name"){
            this.handleSortName();
        }
        else if(val.value === "power"){
            this.handleSortPower();
        }
        else if(val.value === "trackers ok"){
            this.handleSortNbTrackersOk();
        }
        else if(val.value === "trackers manual"){
            this.handleSortNbTrackersManual();
        }
        else if(val.value === "trackers error"){
            this.handleSortNbTrackersError();
        }
        else if(val.value === "winds"){
            this.handleSortWindSpeed();
        }
	}

	render()
	{  
        const nameSorting = 
        <div className="sorting-bar-hover">
            <img
                src="/images/logo/arrows.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting arrows"
            />
            <img
                src="/images/logo/texts.png"
                width="18"
                height="18"
                className="logo-responsive wind"
                alt="Sorting by name"
            />
        </div>;

        const powerSorting = 
        <div className="sorting-bar-hover">
            <img
                src="/images/logo/sunny.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting by power"
                />
            <img
                src="/images/logo/arrows.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting arrows"
            />
            <a className="font-sorting-bar-text">Power</a>
        </div>;

        const trackersOk = 
        <div>
            <img
                src="/images/logo/panel-green.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting by Nb trackers Ok"
            />
            <img
                src="/images/logo/arrows.png"
                width="15"
                height="15"
                className="logo-responsive wind arrows"
                alt="Sorting arrows"
            />
            <span className="font-sorting-bar-text">Trackers Ok</span>
        </div>;

        const trackersManual = 
        <div>
            <img
                src="/images/logo/panel-yellow.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting by Nb trackers manual"
            />
            <img
                src="/images/logo/arrows.png"
                width="15"
                height="15"
                className="logo-responsive wind arrows"
                alt="Sorting arrows"
            />
            <span className="font-sorting-bar-text">Trackers Manual</span>
        </div>;

        const trackersError = 
        <div>
            <img
                src="/images/logo/panel-rouge.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting by Nb trackers error"
            />
            <img
                src="/images/logo/arrows.png"
                width="15"
                height="15"
                className="logo-responsive wind arrows"
                alt="Sorting arrows"
            />
            <span className="font-sorting-bar-text">Trackers Error</span>
        </div>;

        const winds =
        <div className="sorting-bar-hover">
            <img
                src="/images/logo/pinwheel.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting by wind"
                />
            <img
                src="/images/logo/arrows.png"
                width="20"
                height="20"
                className="logo-responsive wind"
                alt="Sorting arrows"
            />
            <a className="font-sorting-bar-text">Wind</a>
        </div>;

        const options =  [
            { value: "name", label: nameSorting },
            { value: "power", label: powerSorting },
            { value: "trackers ok", label: trackersOk },
            { value: "trackers manual", label: trackersManual },
            { value: "trackers error", label: trackersError },
            { value: "winds", label: winds }
        ];

        return (
                <div>
                    <Dropdown options={options} placeholder="Select an option" onChange={this.selectValue}/>
                </div>
            );
	}
}

SortingBarMobile.defaultProps = {
    setSorting: function(){}
};

export default SortingBarMobile;