import { Path, withLeaflet } from 'react-leaflet';
import terminator from '@joergdietrich/leaflet.terminator';

/**
 * Overlay day/night region on a Leaflet map
 * @see https://github.com/joergdietrich/Leaflet.Terminator
 */
class SunArea extends Path
{
	componentDidMount()
	{
		super.componentDidMount();
		this.interval = setInterval(() => {
			const s2 = terminator();
			this.sunArea.setLatLngs(s2.getLatLngs());
			this.sunArea.redraw();
		}, 300000);
	}
	
	componentWillUnmount()
	{
		super.componentWillUnmount();
		clearInterval(this.interval);
	}
	
	createLeafletElement(opts)
	{
	    this.sunArea = terminator();
		return this.sunArea;
	}

}

export default withLeaflet(SunArea);