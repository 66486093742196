const HIERARCHY = {
	ROLE_ANONYMOUS: 0,
	ROLE_GUEST: 1,
	ROLE_OPERATOR: 2,
	ROLE_DIAGNOSTIC: 3,
	ROLE_ADMIN: 4,
	ROLE_SUPER_ADMIN: 5
};

/**
* Access Level Control
* @copyright Optimum Tracker
* @author bblin
*/
class Authorization
{
constructor()
{
	this.modules = [];
}

/**
 * @param Array
 * @return Authorization
 */
setModules(modules)
{
	this.modules = modules;
	return this;
}

/**
 * @param string
 * @return boolean
 */
hasAccess(module)
{
	for(let i in this.modules) {
		if(this.modules[i].name === module) {
			return HIERARCHY['ROLE_ANONYMOUS'] >= HIERARCHY[this.modules[i].access];
		}
	}
	return false;
}

}

export default Authorization;
