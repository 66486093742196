/**
 * Dispatch events between packages
 * @copyright Optimum Tracker
 * @author bblin
 */
 import EventEmitter from 'events';

 const eventDispatcher = new EventEmitter();
 
 eventDispatcher.on('websocket.connect', connect => {
	 if(!connect) eventDispatcher.emit('user.logout', 'Timeout : disconnected');
 });
 
 export default eventDispatcher; 