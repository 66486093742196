import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import AbstractSorting from './Service/AbstractSorting';

/**
 * Sorting bar: Header of the Table.
 * @copyright Optimum Tracker
 * @author wkoffi
 */


class SortingBarTableHeader extends AbstractSorting{

   render(){

    return (
        <thead>
            <tr className="sorting-bar-bg">
                <th className="text-left border-sorting-bar sorting-bar-hover table-sorting-name" onClick={this.handleSortName}> 
                    <img
                        src="/images/logo/arrows.png"
                        width="20"
                        height="20"
                        className="logo-responsive wind2-safety"
                        alt="Sorting arrows"
                    />
                    <img
                        src="/images/logo/texts.png"
                        width="20"
                        height="20"
                        className="logo-responsive wind2-safety"
                        alt="Sorting by name"
                    />
                </th>
                <th className="text-center border-sorting-bar sorting-bar-hover" onClick={this.handleSortPower}>
                    <img
                        src="/images/logo/sunny.png"
                        width="20"
                        height="20"
                        className="logo-responsive wind2-safety"
                        alt="Sorting by power"
                        />
                    <img
                        src="/images/logo/arrows.png"
                        width="20"
                        height="20"
                        className="logo-responsive wind2-safety"
                        alt="Sorting arrows"
                    />
                    <a className="font-sorting-bar-text">Power</a>
                </th>
                <th colSpan="3" className="text-center border-sorting-bar sorting-bar-hover">
                    <img
                        src="/images/logo/solar-panel.png"
                        width="20"
                        height="20"
                        className="logo-responsive wind2-safety"
                        alt="Sorting by Nb trackers"
                    />
                    <img
                        src="/images/logo/arrows.png"
                        width="20"
                        height="20"
                        className="logo-responsive wind2-safety"
                        alt="Sorting arrows"
                    />
                    <DropdownButton
                        bsStyle="default"
                        title="Trackers"
                        noCaret
                        id="dropdown-no-caret"
                        className="font-sorting-bar-text text-trackers"
                    >
                        <MenuItem eventKey="1" onClick={this.handleSortNbTrackersOk}>
                            <img
                                src="/images/logo/panel-green.png"
                                width="20"
                                height="20"
                                className="logo-responsive wind"
                                alt="Sorting by Nb trackers Ok"
                                />
                            <span className="font-sorting-bar-text-blue">Trackers ON</span>
                            <img
                                src="/images/logo/arrow.svg"
                                width="20"
                                height="20"
                                className="logo-responsive wind arrows"
                                alt="Sorting arrows"
                            />
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem eventKey="2" onClick={this.handleSortNbTrackersManual}>
                            <img
                                src="/images/logo/panel-yellow.png"
                                width="20"
                                height="20"
                                className="logo-responsive wind"
                                alt="Sorting by Nb trackers manual"
                                />
                            <span className="font-sorting-bar-text-blue">Trackers Manual</span>
                            <img
                                src="/images/logo/arrow.svg"
                                width="20"
                                height="20"
                                className="logo-responsive wind arrows"
                                alt="Sorting arrows"
                            />
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem eventKey="3" onClick={this.handleSortNbTrackersError}>
                            <img
                                src="/images/logo/panel-rouge.png"
                                width="20"
                                height="20"
                                className="logo-responsive wind"
                                alt="Sorting by Nb trackers error"
                                />
                            <span className="font-sorting-bar-text-blue">Trackers Off</span>
                            <img
                                src="/images/logo/arrow.svg"
                                width="20"
                                height="20"
                                className="logo-responsive wind arrows"
                                alt="Sorting arrows"
                            />
                        </MenuItem>
                    </DropdownButton>
                </th>
                <th colSpan="2" className="text-center border-sorting-bar sorting-bar-hover" onClick={this.handleSortWindSpeed}>
                    <img
                        src="/images/logo/pinwheel.png"
                        width="20"
                        height="20"
                        className="pinwheel logo-responsive wind2-safety"
                        alt="Sorting by wind"
                        />
                    <img
                        src="/images/logo/arrows.png"
                        width="20"
                        height="20"
                        className="logo-responsive wind2-safety"
                        alt="Sorting arrows"
                    />
                    <a className="font-sorting-bar-text">Wind</a>
                </th>
                <th className="border-sorting-bar"></th>
            </tr>
    </thead>
    );
  }

}

SortingBarTableHeader.defaultProps = {
    setSorting: function(){}
};
export default SortingBarTableHeader;