import React from 'react';
import './Body.css';
import 'react-dragswitch/dist/index.css';
import { Navbar, NavItem , Nav, NavDropdown, MenuItem } from 'react-bootstrap';

/**
 * Filter bar of the application.
 * Filter bar contains a filter and a navbar.
 * @copyright Optimum Tracker
 * @author wkoffi
 */

class Filters extends React.Component
{
  	constructor(props)
    {
      super(props);
      this.state = {
        filterName: "",
        checkedWindSafety:false,
        checkedLostRemoteConnection:false
      }
      this.handleChangeWindSafety = this.handleChangeWindSafety.bind(this);
      this.handleChangeLostRemoteConnection = this.handleChangeLostRemoteConnection.bind(this);
    }

    handleChangeName = event => {
      this.setState({ filterName: event.target.value });
      this.props.setFilter('name', event.target.value);
    };

    handleChangeWindSafety() {
      this.setState({ checkedWindSafety: !this.state.checkedWindSafety });
      this.props.setFilter('windSafety', !this.state.checkedWindSafety);
    }

    handleChangeLostRemoteConnection() {
      this.setState({ checkedLostRemoteConnection: !this.state.checkedLostRemoteConnection });
      this.props.setFilter('lostRemoteConnection', !this.state.checkedLostRemoteConnection);
    }

    render()
    {
      const isFilterEnable = (this.state.checkedWindSafety || this.state.checkedLostRemoteConnection);
    
      return (
          <Navbar fluid style={{background:'transparent', border:'0', marginBottom:'-52px'}}>
            <Navbar.Header>
              <Navbar.Brand>
              <a href="http://www.optimum-tracker.com" rel="noopener noreferrer" target="_blank">
                  <img
                  src="/images/logo/logo-optimum-tracker-white2.png"
                  width="170px"
                  height="40px"
                  left="60px"
                  top="19px"
                  className="logo-optimum-tracker"
                  alt="Optimum Tracker"
                />
                </a>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav>
                <NavItem eventKey={1}>
                  <div className="search-width">
                    <input className="success-responsive" id="search" value={this.state.filterName} onChange={this.handleChangeName}/>	
                  </div>
                </NavItem>
                <NavDropdown eventKey={3} title="" id={isFilterEnable ? "basic-nav-dropdown-active":"basic-nav-dropdown"} style={isFilterEnable ?{paddingTop:'12px'}:{}}>
                  <MenuItem eventKey={3.1} className={this.state.checkedWindSafety ? "active-menu":""} onClick={this.handleChangeWindSafety}>
                      <img
                      src="/images/logo/shields.png"
                      width="20"
                      height="20"
                      className="logo-responsive wind"
                      alt="Wind safety"
                      />
                    <span className="font-filters-name">Safety</span>
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={3.2} className={this.state.checkedLostRemoteConnection ? "active-menu":""} onClick={this.handleChangeLostRemoteConnection}>
                      <img
                        src="/images/logo/lostconnection5.png"
                        width="25"
                        height="25"
                        className="logo-responsive lost-connection"
                        alt="Lost remote connection"
                        />
                      <span className="font-filters-name">Connection</span>
                  </MenuItem>
                </NavDropdown>
              </Nav>
              <Nav pullRight>
                <NavItem eventKey={1}>
                <div id="power">
                  <p className="title-optimum-tracker">
                    <img
                    src="/images/logo/power-white.png"
                    width="20"
                    height="20"
                    className="logo-optimum-tracker-power"
                    alt="Optimum Tracker"
                  />
                  { this.props.total.toFixed(2) } MWp</p>
              </div>
                </NavItem>
              </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
  
  }


Filters.defaultProps = {
	setFilter: function(){}
};

export default Filters;
