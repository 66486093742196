import React from 'react';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import Location from './Location';
import ZoomLevel from './lib/ZoomLevel';
import SunArea from './SunArea';
import services from '../../lib/Services';
import 'leaflet/dist/leaflet.css';
import './Map.css';

import { DragSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';
import Modal from 'react-awesome-modal';
import { useMediaQuery } from 'react-responsive';

class Overview extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			satellite: false,
			visible : false,
			height: null,
			width: null,
			accessToken: services.getPlanMap(),
			selectedPowerplant: null,
			// Tip to fix mapCenter and zoom bug
			loadFinished: false
		}
	}

	handleChange(){
		if (!this.state.satellite){
			
			this.setState({accessToken: services.getSatelliteMap()});
		}
		else{
			this.setState({accessToken: services.getPlanMap()});
		}
	}

	getMapCoordinates(powerplants)
	{
		if(powerplants.length === 0) {
			return [
				[0, 0], 1
			]
		}

		if (this.props.selectedPowerplantOnMap !== null) {
			powerplants = powerplants.filter(pp => pp[0] === this.props.selectedPowerplantOnMap);
		}

		let [min_lat, max_lat, min_lon, max_lon] = this.getBorders(powerplants);
		let mapCenter = this.getMapCenter(min_lat, max_lat, min_lon, max_lon);
		let zoom = this.getZoom(min_lat, max_lat, min_lon, max_lon);
		return [mapCenter, zoom];
	}

	componentDidUpdate(prevProps)
	{
		if(this.props.selectedPowerplantOnMap !== prevProps.selectedPowerplantOnMap) {
			this.openModal();
		}
    }

    componentDidMount()
    {
    	// Tip to fix mapCenter and zoom bug
    	setTimeout(() => this.setState({loadFinished: true}), 2000);
    }

	componentWillMount()
	{
		this.setState({height: (window.innerHeight - (window.innerHeight/9)) + 'px'});
		this.setState({width: window.innerWidth});
	}

	getMapCenter(min_lat, max_lat, min_lon, max_lon)
	{
		return [
			min_lat + (max_lat - min_lat) / 2,
			min_lon + (max_lon - min_lon) / 2
		];
	}

	getZoom(min_lat, max_lat, min_lon, max_lon)
	{
		return ZoomLevel.get(min_lat, min_lon, max_lat, max_lon);
	}

	getBorders(powerplants)
	{
		let min_lat = null;
		let max_lat = null;
		let min_lon = null;
		let max_lon = null;
		powerplants.forEach( pp => {
			if(min_lat === null || min_lat > Number(pp[1].latitude)) {
				min_lat = Number(pp[1].latitude);
			}
			if(max_lat === null || max_lat < Number(pp[1].latitude)) {
				max_lat = Number(pp[1].latitude);
			}
			if(min_lon === null || min_lon > Number(pp[1].longitude)) {
				min_lon = Number(pp[1].longitude);
			}
			if(max_lon === null || max_lon < Number(pp[1].longitude)) {
				max_lon = Number(pp[1].longitude);
			}
		});
		return [min_lat, max_lat, min_lon, max_lon];
	}

	openModal()
	{
        this.setState({visible : true});
    }

    closeModal()
    {
        this.setState({visible : false});
    }

    Mobile = ({ children }) => {
        const isMobile = useMediaQuery({maxWidth: 1200 })
        return isMobile ? children : null
    }

    Default = ({ children }) => {
        const isNotMobile = useMediaQuery({ minWidth: 1201 })
        return isNotMobile ? children : null
    }

	// Tip to Display the map or not on mobile
	modaldisplayStyle = function(options) {
		return {
			zIndex: this.state.visible ? '1':'-100',
			position:'absolute'
		}
	}

	setFilter = (app_id) => {
		this.setState({selectedPowerplant: app_id});
		return this.props.setFilter('app_id', app_id);
	}

	getMapLeaflet()
	{
		const isHighWidth = (this.state.width > 1200);
		const pps = Array.from(this.props.powerplants.entries());
		let [mapCenter, zoom] = this.getMapCoordinates(pps);
		// Tip to fix mapCenter and zoom bug
		if(this.state.loadFinished) {
			mapCenter = [mapCenter[0]+0.01, mapCenter[1]+0.01];
		}
		const locations = pps.map( val => 
							<Location 
								key={val[0]}
								app_id={val[0]}
								name={val[1].name}
								lat={Number(val[1].latitude)}
								lon={Number(val[1].longitude)}
								setFilter={this.setFilter}
								selected={this.state.selectedPowerplant === val[0]} /> );

		return <LeafletMap className={isHighWidth ?"leaflet-map":"leaflet-map-mobile"} style={{height: isHighWidth ? this.state.height:'100%'}} center={mapCenter} zoom={zoom}>
					<TileLayer
						url={this.state.accessToken}
						attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a>-<a href=&quot;https://www.mapbox.com/&quot;> Mapbox </a> " />
					{ locations }
					<SunArea />
					<div className="toogle-map">
						<DragSwitch
							checked={this.state.satellite}
							offColor='#3E8CC7' 
							handleColor='#FFFFFF' 
							focusShadow='rgba(0,0,0,1) 0px 0px 2px 3px'
							onChange={c => {
							this.setState({ satellite: c })
							this.handleChange()
							}}
							/>
					</div>
				</LeafletMap>;
	}

	render()
	{
		return (
				<div>
					<this.Default>
						{ this.getMapLeaflet() }
					</this.Default>
					<this.Mobile>
						<div style={this.modaldisplayStyle()}>
							<Modal visible width="80%" height="40%" effect="fadeInUp" onClickAway={() => this.closeModal()}>
							{ this.getMapLeaflet() }
							</Modal>
						</div>	
					</this.Mobile>
				</div>
		);
	}
}

Overview.defaultProps = {
	powerplants: new Map(),
	selectedPowerplantOnMap: null,
	setFilter: function(){}
};

export default Overview;