import { services as websocket } from 'react-otmonitor-websocket';
import User from './User';
import Authorization from './Authorization';
import Api from './Api';
import eventDispatcher from './EventDispatcher';

/**
 * Provide all singleton services
 * @copyright Optimum Tracker
 * @author bblin
 */
class Services
{
	constructor()
	{
		this.instances = new Map();
		this.initDependencies()
	}
	
	/**
	 * Inject parameters in third-parties bundles
	 */
	initDependencies()
	{
		websocket.setParam('WEBSOCKET_URL', process.env.REACT_APP_WEBSOCKET_URL);
		websocket.setParam('dispatcher', this.getEventDispatcher());
	}
	
	getUser()
	{
		if(!this.instances.has('User')) {
			this.instances.set('User', new User(process.env.REACT_APP_LOGIN_URL, this.getEventDispatcher()));
		}
		return this.instances.get('User');
	}
	
	getAuthorization(app_id)
	{
		if(!this.instances.has('Authorization' + app_id)) {
			this.instances.set('Authorization' + app_id, new Authorization());
		}
		return this.instances.get('Authorization' + app_id);
	}
	
	getApi()
	{
		if(!this.instances.has('Api')) {
			this.instances.set('Api', new Api(this.getUser(), process.env.REACT_APP_REST_URL));
		}
		return this.instances.get('Api');
	}
	
	getMonitoringUrl(app_id)
	{
		return process.env.REACT_APP_MONITORING_URL + '?token=' + this.getUser().getToken(app_id).get();
	}
		
	getEventDispatcher()
	{
		return eventDispatcher;
	}

	getPlanMap(){
		return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
	}

	getSatelliteMap(){
		return "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoiam9vcDMzNSIsImEiOiJja2ZtbGczcTgwMmVsMnptcHM5bTd2b2psIn0.aryiEfxVO29Vdj85qhbHJw";
	}
	
}

var services = new Services();

export default services;