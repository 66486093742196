import axios from 'axios';

/**
 * Send business request to API
 * 
 * @copyright Optimum Tracker
 * @author bblin
 */
class Api
{
	constructor(user, url)
	{
		this.user = user;
		this.url = url;
	}
	
	/**
	 * Get Powerplant info
	 */
	getPowerplant(id_app)
	{
		return new Promise((resolve, reject) => {
			this.restGetMethod(`/parameters`, id_app).then(parameters => {
				const powerplant = {};
				parameters.forEach( parameter => {
					powerplant[parameter.name] = parameter.value;
				});
				resolve(powerplant);
			});
		});
	}
	
	/**
	 * Get modules
	 */
	getModules(id_app)
	{
		return this.restGetMethod(`/modules`, id_app);
	}
	
	/**
	 * Set headers according user token
	 */
	setToken(id_app)
	{
		return {
			headers: {
				'Authorization': 'Bearer ' + this.user.getToken(id_app).get()
			}
		};
	}
	
	/**
	 * Send a GET request
	 * @param string
	 */
	restGetMethod(suffix_url, id_app)
	{
		return axios.get(this.url + suffix_url, this.setToken(id_app))
					.then(response => {
						return response.data;
					})
					.catch(error => {
						if(error.response.status === 401) {
							this.user.signOut(error.response.data.message);
						} else {
							console.error(error);
						}
						return [];
					});	
	}
				
}

export default Api;