import React from 'react';
import { Modal } from 'react-bootstrap';
class AllModals extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        contact: false,
        mentions: false
      };
    }
  
    handleCloseContact = () => {
      this.setState({ contact: false });
    }
  
    handleShowContact = () => {
      this.setState({ contact: true });
    }

    handleCloseMentions = () => {
        this.setState({ mentions: false });
      }
    
    handleShowMentions = () => {
        this.setState({ mentions: true });
      }
  
    render() {

    const contact = "+33 4 13 41 97 05";
    const mail = "contact@optimum-tracker.com";
  
      return (
        <div>
          <div className="bottom-bar">
                <div id="mentions">
                    <img
                      src="/images/logo/credit1.png"
                      width="30"
                      height="30"
                      className="contact"
                      onClick={() => this.handleShowMentions()}
                      alt="Optimum Tracker Credit"
                  />
                </div>
                <div id="contact">
                    <img
                      src="/images/logo/Logo-contact.png"
                      width="30"
                      height="30"
                      className="contact"
                      onClick={() => this.handleShowContact()}
                      alt="Optimum Tracker Contact"
                  />
                </div>
         </div>
          <Modal show={this.state.contact} onHide={this.handleCloseContact} bsSize="sm" aria-labelledby="contained-modal-title-sm" className="modal-vertical-centered">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-sm"><p className="text-center modal-contact-desktop">Contact Optimum Tracker</p></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-tel-desktop">
                    <p className="tel-contact-position"><img src="/images/logo/phone-ringing.png" width="25" height="25" id="contact-phone" alt="Optimum Tracker Contact Phone"/> {contact} </p>
                    <p className="text-center"><img src="/images/logo/mail.png" width="25" height="25" className="contact-mail" alt="Optimum Tracker Contact Mail"/> {mail} </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.mentions} onHide={this.handleCloseMentions} bsSize="sm" aria-labelledby="contained-modal-title-sm" className="modal-vertical-centered">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-sm"><p className="text-center modal-contact-desktop">Credits</p></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-tel-desktop">
                    <p className="text-center modal-credit-desktop">Icon made by authors from <a href="https://www.flaticon.com/" rel="noopener noreferrer" target="_blank">www.flaticon.com</a></p>
                    <p className="text-center modal-credit-desktop">Photo credit <a href="https://www.optimum-tracker.com/" rel="noopener noreferrer" target="_blank">Optimum Tracker</a></p>
                </div>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }
  
export default AllModals;