import React from 'react';
import App from '../App';
import { FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap';
import { FaUser, FaLock, FaSpinner, FaExclamationTriangle } from 'react-icons/fa';
import services from '../../lib/Services';
import './Login.css';
import LoginNavbar from './LoginNavbar';
import AllModals from './AllModals';

const NB_BACKGROUND_IMAGES = 7;

/**
 * Display login screen
 * @copyright Optimum Tracker
 * @author wkoffi
 */
class Login extends React.Component
{
	constructor(props)
	{
		super(props);
		this.usernameField = null;
		this.state = {
				username:'',
				password:'',
				error: '',
				loading: false,
				logged: false
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount(){
		const numImg = Math.floor(Math.random() * (NB_BACKGROUND_IMAGES - 1)) + 1;
		document.body.style.backgroundImage = `url('/images/bg/img${numImg}.jpg')`;
	}
	
	componentWillUnmount(){
		document.body.style.backgroundColor = null;
	}

	componentDidMount()
	{
		if(this.usernameField !== null) {
			this.usernameField.focus();
		}
	}
	
	handleChange(event)
	{
		this.setState({[event.target.name]: event.target.value});
	}
	
	handleSubmit(event)
	{
		event.preventDefault();
		this.setState({error: '', loading: true, logged: false});
		services.getUser().signIn(
				this.state.username, 
				this.state.password, 
				(success) => this.setState({loading: false, logged: true}),
				(message) => this.setState({error: message, loading: false, logged: false})
		);
	}
	
	renderForm()
	{
		let messageBox = '';
		if(this.state.error !== '') {
			messageBox = <span className="alert alert-danger" role="alert"><FaExclamationTriangle />&nbsp;{ this.state.error }</span>;
		}


		
		return (
            <div>
                <LoginNavbar/>
                <div className="form-prem">
                    <div className="form">
                        <div className="Logo-self-align">
                            <h1 className="titre text-center">Global Monitoring</h1>
                        </div>
                        <div>
                        <p className="login-box-msg">{ messageBox }</p>
                        </div>
                        <div>
                            <form onSubmit={this.handleSubmit} style={{marginTop:'-30px'}}>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Addon className="b-input"><FaUser style={{color: '#0087b1'}} size={14} /></InputGroup.Addon>
                                        <FormControl className="b-input" inputRef={ref => { this.usernameField = ref }} type="text" name="username" onChange={this.handleChange} />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroup.Addon className="b-input"><FaLock style={{color: '#0087b1'}} size={14} /></InputGroup.Addon>
                                        <FormControl className="b-input" type="password" name="password" onChange={this.handleChange} />
                                    </InputGroup>
                                </FormGroup>
                                <Button type="submit" className="b-input btn-block" disabled={this.state.loading} style={{marginTop: '30px'}} >
                                    { this.state.loading ? <FaSpinner size={20} className="icon-spin" style={{color: '#0087b1'}} /> :  
                                    <img
                                    src="/images/logo/Log-inbleu.png"
                                    width="25px"
                                    height="32px"
                                    className="mx-auto"
                                    alt="Optimum Tracker Log-in"
                                    style={{color: '#0087b1'}}
                                    />
                                    }   
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
                <AllModals/>   
          </div>
		);
	}
	
	render()
	{
		return ( this.state.logged ? <App /> : this.renderForm() );
	}
}

export default Login;