import axios from 'axios';
import Token from './Token';
import { services as websocket } from 'react-otmonitor-websocket';

/**
 * Handle user authentication
 * @copyright Optimum Tracker
 * @author bblin
 */
class User
{
	/**
	 * @param string : url of API
	 * @param EventEmitter
	 */
	constructor (loginUrl, dispatcher)
	{
		this.loginUrl = loginUrl;
		this.username = null;
		this.tokens = new Map();
		this.attachEvents(dispatcher);
	}
	
	/**
	 * @return string
	 */
	getToken(id_app)
	{
		if(!this.tokens.has(id_app)) {
			throw new Error('No token for the app ' + id_app);
		}
		return this.tokens.get(id_app);
	}
	
	/**
	 * @param EventEmitter
	 */
	attachEvents(dispatcher)
	{
		dispatcher.on('user.logout', msg => this.signOut(msg));
	}
	
	/**
	 * @return array
	 */
	getAllAppId()
	{
		return Array.from(this.tokens.keys());
	}
	
	/**
	 * @return string
	 */
	getName()
	{
		return this.username;
	}
		
	/**
	 * Send an authentication request on API
	 * @param string
	 * @param string
	 * @param string
	 * @param function
	 * @param function
	 */
	signIn(username, password, thenCallback, catchCallback)
	{
		const url = this.loginUrl + '/global_login_check';
		
		const payload = {
				"username": username.toLowerCase(),
				"password": password
		};

		axios
			.post(url, payload)
			.then((response) => {
				this.username = username;
				for(let id_app in response.data.token) {
					const token = new Token();
					token.set(response.data.token[id_app]);
					this.tokens.set(id_app, token);
					websocket.setParam('token' + id_app, token);
				}
				thenCallback();
			})
			.catch((error) => {
				this.username = null;
				this.tokens = new Map();
				catchCallback(this.signInErrorMessage(error));
			});
	}
	
	/**
	 * Manage error message from request response
	 * @param Object
	 * @return string
	 */
	signInErrorMessage(error)
	{
		if(error.response !== undefined && error.response.status === 401) {
			return error.response.data.message;
		} else {
			return "Internal Error. Please try again later";
		}
	}
	
	signOut()
	{
		document.location.href = '/';
	}
		
}

export default User;